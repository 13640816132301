
.featured__categories__container {
    height: auto;
    width: 100%;
    display: flex;
    min-height: 25rem;
    margin-top: 40px;
    flex-wrap: wrap;
}

.featured__categories{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.featured__categories__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.featured__header__big {
    font-size: 2.3rem;
    font-weight: 600;
}

.featured__header__small {
    margin: auto 20px;
}

.featured__categories__header__line {
    width: 200px;
    height: 6px;
    background-color: #478778;
    align-self: center;
    border-radius: 25px;
}

.featured__categories__card__container {
    margin: 20px auto;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0;

}
