
.register__card__container {
    height: 650px;
    width: 420px;
    margin: auto;
    box-shadow: 0px 2px 5px -2px;
}

.register__card {
    height: 100%;
    width: 90%;
    margin: auto;
    padding: 10px;
}

.register__header {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.register__header h1 {
    font-size: 2.5rem;
    font-weight: bolder;

}

.register__inputs {
    height: 70%;
    width: 100%;
}

.reg__input__container {
    height:19%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.input__label {
    height: 40%;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
}

.register__input {
    width: 100%;
    height: 50%;
}

.register__button__container {
    height: 25%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register__button {
    height: 50%;
    width: 100%;
    font-size: 1.1rem;
    background-color: #478778;
    border-color: #478778;
    border-style: dashed;
    font-weight:bold;
}

.register__other__actions {
    height: 20%;
    width: 100%;
}
