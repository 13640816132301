.tab__header {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    background-color: #f8f8f8;
    padding: 2px;
    border-bottom:1px solid #ddd;
    overflow-x: auto; /* Enables horizontal scrolling if content overflows */
    white-space: nowrap; /* Keeps items in a single row */
}

.tab__header a {
    text-decoration: none;
    color: #333;
    padding: 2px 2px;
    font-size: 16px;
    transition: color 0.3s;
    flex-shrink: 0; /* Prevents items from shrinking too much */
}

.tab__header a:hover {
    color: #007bff; /* Change color on hover */
}

/* Optional: Better touch scrolling experience on mobile */
.tab__header {
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}


.control__bar__container {
    height: auto;
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1000;
}

.controls__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 80%;
    height: 50px;
}

.control {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #478778;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
}

.control:hover {
    background-color: #478778;
    color: black;
}
