

.item__container {
    height: auto;
    width: 100%;
}

.detail__and__carousel__container {
    height: auto;
    width: 80%;
    display: flex;
    margin-left: 8%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.item__description__container {
    height: auto;
    width: 100%;
}

.related__items__container {
    height: auto;
    width: 100%;
}
