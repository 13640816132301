
.featured__products__container {
    width: 100%;
    height: auto;
    min-height: 25rem;
    margin-top: 30px;
}

.featured__products{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.featured__products__header {
    width: 92%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    text-align: center;
}

.featured__items__header__big {
    font-size: 2rem;
    font-weight: 600;
}

.featured__header__small {
    margin: auto 20px;
}

.featured__products__header__line {
    width: 150px;
    height: 6px;
    background-color: #478778;
    align-self: flex-start;
    margin-left: 50px;
    border-radius: 25px;
}

.featured__products__card__container {
    margin-top: 20px;
    padding: 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
