.product__carousel__container {
    height: auto;
    width: 50%;
    min-width: 400px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__carousel {
    height: 100%;
    width: 1024px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel {
    height: 100%;
}

.carousel__image__container{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: center;

    align-items: center;
}

.carousel__image {
    max-width: 35%;
    border-radius: 30px;
    height: auto;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .carousel__image {
        max-width: 70%;
        margin-left: 8% !important;
        height: auto;
        margin: 0;
        height: 350px;
    }
    .product__carousel__container {
        height: 420px;
        width: 85%;
    }
    .carousel__image__container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* .carousel-inner {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-item {
    margin: auto;
} */
