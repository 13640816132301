.nav__top__container {
    height: 70px;
    width: 100%;
    display: flex;
    
}

.top__container {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.form__container {
    width: 50%;
    min-height: 10px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-self: center;
}

.control__bar {
    align-self: center;
    height: auto;
    width: auto;
    z-index: 1000;
}

.drawer {
    display: none;
    height: auto;
    width: 80px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .nav__top__container {
        height: auto;
    }
    .form__container {
        display: none;
    }
    .top__container {
        justify-content: space-between;
    }
}

@media screen and (max-width: 780px) {
    .control__bar{
       display: none;
    }
    .drawer{
        display: flex;
    }
}