

.product__card__card {
    height: auto;
    width: 270px;
    margin: 14px;
    border-width: 1px;
    box-shadow: 0px 2px 6px -3px;
    border-radius: 5px;
    padding-top: 10px;
}

.product__card {
    height: 100%;
    width: 100%;
}

.product__image {
    height: 250px;
    width: auto;
    display: flex;
    justify-content: first baseline;
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__image > img{
    max-width: 100%;
    height: 260px !important;
    transition: 1s;
    margin: 0px auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.product__card__detail {
    height: 20%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff ;
}

.product__name {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 4px 10px;

}

.product__description {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
}

.product__price {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
}

.product__card__action {
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 5px;
}
