
.product__description__product {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-self: center;
    margin: 50px auto;
    padding: 30px;
    padding-left: 23.5%;
}

.description__header__container {
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.description__header__line {
    height: 50%;
    width: 2px;
    background-color: rgb(0, 0, 0);
}

.description__header {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.4rem;
}

.description__detail__container {
    width: 90%;
    height: 40%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.description__detail {
    width: 60%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.description__highlights__header {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}


.description__specifics__container {
    width: 90%;
    height: 40%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
