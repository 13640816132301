@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@500&family=Ubuntu:wght@500&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    color: #000;
}


a:hover {
    text-decoration: none;
}