

.category__card__card {
    height: 480px;
    width: 320px;
    margin: 20px;
   /*  border-style: groove;
    border-color: black;
    border-width: 1px; */
    box-shadow: 0px 2px 5px -2px ;
    position: relative;
}

.category__card {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category__image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    position: absolute;
}

.category__image > img{
    max-width: 100%;
    height: auto;
    transition: 1s;
}

.category__card__detail {
    height: 10%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    bottom: 0%;
    color: #478778;
}

.category__name {
    height: 50%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
}

.category__description {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
}

.category__card__action {
    width: 40%;
    height: 80%;
    bottom: 0%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.category__card__action__button {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 0.8rem;
    background-color: transparent;
    border-color: #478778;
    border-style: solid;
    font-weight:bold;
    color: #478778;
}
