
.cart__items__container {
    width: 100%;
    min-width: 200px;
    min-height: 100px;
    height: 85%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.cartItems{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    /* align-items: flex-start; */
    position: relative;
    flex-direction: column;
}

.cart__header {
    width: 100%;
    height: 50px;
    text-align: center;
}

.shop__cart__items {
    width: 100%;
    height:90%;
    margin-bottom: 50px;
}

.cart__empty {
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 300;
}

.options {
    width: 100%;
    height: auto;
}

.total__amount {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #f1f1f1;
   /*  position: absolute; */
    margin: auto;
    bottom: 0%;
}

.total__amount__label {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: auto 10px;
    font-weight: 700;
}

.total__amount__value {
    width: 20%;
    min-width: 100px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;

}

.checkout {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signpad {
    border:grey 1px solid;
    width:35%;
    margin-top: 12px;
    float: right;
    border-radius: 15px;
}

@media (max-width: 768px) {
    .signpad {
      width:100%
    }

}

.animation {
    position: relative;
    animation-name: example;
    animation-duration: 2s;
  }

  @keyframes example {
    0%   {left:0px; top:0px;}
    25%  {left:10px; top:0px;}
    100% {left:0px; top:0px;}
  }
